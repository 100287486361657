<template>
    <div>
        <b-modal id="authModal" centered>
            <div class="modalTitle">
                <h2>Welcome to KJ Real State</h2>
                <span class="closeModal"  @click="$bvModal.hide('authModal')">
                    <span class="icon-close"></span>
                </span>
            </div>
            <div v-if="passwordForgotModal === false" class="modalContent pt-3">
                <b-tabs content-class="mt-3">
                    <b-tab title="Sign In" active >
                        <LoginForm @forgot-password-modal="handleForgotPassword($event)" />
                    </b-tab>
                    <b-tab title="New Account">
                        <RegisterForm />
                    </b-tab>
                </b-tabs>
            </div>
            <div v-if="passwordForgotModal" class="modalContent pt-3">
                <ForgotPassword/>
            </div>
        </b-modal>
    </div>
</template>
<script>
import LoginForm from "@/views/Signin/LoginForm"
import RegisterForm from "@/views/Signup/RegisterForm"
import ForgotPassword from '../views/Signin/ForgotPassword'
export default {
    name: 'AuthModal',
    components:{
        RegisterForm,
        LoginForm,
        ForgotPassword,
    },
    data() {
        return {
            showModal: true,
            passwordForgotModal:false,
        };
    },
    mounted() {
        let app = this;
        app.$root.$on("redirect-to-login", ($event) => {
            app.passwordForgotModal = $event;
        });
    },
    methods: {
        closeModal() {
            this.$bvModal.hide('authModal')
        },
        handleForgotPassword($event){
            let app = this;
            app.passwordForgotModal = $event;
        },
    }
}
</script>