import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import createPersistedState from "vuex-persistedstate"
import * as Cookies from "js-cookie"

import router from "@/router"
import Auth from "./modules/auth"
import Helper from "@/services/helper"

Vue.use(Vuex)
let expiryTime = new Date();
expiryTime.setTime(expiryTime.getTime() + (604800 * 1000));// 60 => 1 min

const vuexStore = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["Auth"],
      key:"auth",
      storage: {
          getItem: (key) => Cookies.get(key),
          setItem: (key, value) =>Cookies.set(key, value, { expires: expiryTime, secure: false }),
          removeItem: (key) => Cookies.remove(key),
      }
    }),
  ],
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    Auth,
  },
})
axios.interceptors.request.use((config) => {
    config.headers['Authorization'] = config.headers['Authorization'] ?? (vuexStore.getters.accessToken != "" ? "Bearer " + vuexStore.getters.accessToken : "");
    return config;  
});
axios.interceptors.response.use(response => response, function (error) {
    if (error?.response?.data?.message == 'Unauthorized' || error?.response?.status == 418) {
        Helper.clearToken()
        router.push({ name: "Home" });
    }
    return Promise.reject(error);
});
export default vuexStore