<template>
<div v-if="optVerificationFrom">  
    <OtpVerification :email="registerReq.email" @redirect-to-register="handleRegisterForm($event)" />
</div>
<div class="registerForm" v-else >
    <div class="form-group mb-3">
        <label>Full Name <span class="required-field">*</span></label>
        <input type="text"  class="univ-input" v-model="registerReq.name" >
        <span class="error-field" v-if="registerErrors.name"><span class="icon-warning"></span> {{ registerErrors.name }}</span>
    </div>
    <div class="form-group mb-3">
        <label>Email Address <span class="required-field">*</span></label>
        <input type="text"  class="univ-input" v-model="registerReq.email" >
        <span class="error-field" v-if="registerErrors.email"><span class="icon-warning"></span> {{ registerErrors.email }}</span>
    </div>                            
    <div class="form-group mb-3">
        <label>Phone Number <span class="required-field">*</span></label>
        <input type="text"  class="univ-input" v-model="registerReq.mobile_number" >
        <span class="error-field" v-if="registerErrors.mobile_number"><span class="icon-warning"></span> {{ registerErrors.mobile_number }}</span>
    </div>
    <div class="form-group mb-3">
        <label>Password <span class="required-field">*</span></label>
        <input type="password"  class="univ-input" v-model="registerReq.password" >
        <span class="error-field" v-if="registerErrors.password"><span class="icon-warning"></span> {{ registerErrors.password }}</span>
    </div>
    <div class="form-group mb-4">
        <label>Confirm Password <span class="required-field">*</span></label>
        <input type="password"  class="univ-input" v-model="registerReq.password_confirmation">
    </div>
    <div class="form-group">
        <span class="error-message" v-if="registerErrors.message"><span class="icon-warning"></span> {{ registerErrors.message }}</span>
        <button class="univ-btn w-100" @click="userRegister()"><b-spinner v-if="loginLoader" small></b-spinner> Register</button>
    </div>
</div>
</template>
<script>
import axios from 'axios';
import api from '@/services/api';
import OtpVerification from "@/views/Signup/OtpVerification"
export default {
    components:{
        OtpVerification,
    },
    data() {
        return {
            registerReq:{
                name:"",
                email: "",
                mobile_number:"",
                password: "",
                password_confirmation:"",
            },
            registerErrors: {
                name:"",
                email: "",
                mobile_number:"",
                password: "",
                message:"",
            },
            loginLoader: false,
            optVerificationFrom: false,
        };
    },
    methods: {
        userRegister(){
            let app = this;
            app.clearedError();
            app.loginLoader = true;
            axios.post(api.getUrl('/register'),app.registerReq).then((response) => {
                if(response.data.success == true){
                    app.loginLoader = false;
                    app.optVerificationFrom = true;
                }else{
                    if(response.data.message){

                        app.loginLoader = false;
                        app.registerErrors.message = response.data.message;

                    }else if(response.data.errors.length > 0){

                        app.loginLoader = false;
                        response.data.errors.forEach(function(item) {
                            if(item.key == "name"){
                                app.registerErrors.name = item.message;
                            }else if(item.key == "email"){
                                app.registerErrors.email = item.message;
                            }else if(item.key == "mobile_number"){
                                app.registerErrors.mobile_number = item.message;
                            } else if(item.key == "password"){
                                app.registerErrors.password = item.message;
                            }
                        }); 
                    }
                }
            });
        },
        clearedError(){
            let app = this;
            app.registerErrors.name         = "";
            app.registerErrors.email        = "";
            app.registerErrors.mobile_number= "";
            app.registerErrors.password     = "";
            app.registerErrors.message      = "";
        },
        clearedField(){
            let app = this;
            app.registerReq.name         = "";
            app.registerReq.email        = "";
            app.registerReq.mobile_number= "";
            app.registerReq.password     = "";
            app.registerReq.password_confirmation= "";
        },
        handleRegisterForm($event){
            let app = this;
            app.clearedField();
            app.optVerificationFrom = $event;
        }
    }
}
</script>