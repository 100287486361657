<template>
    <div>
        <div class="form-group row">
            <div class="col-xl-6 mt-3">
                <label>Name <span class="required-field">*</span></label>
                <input type="text" class="univ-input" v-model="formReq.name" />
                <span class="error-field" v-if="formErrors.name"><span class="icon-warning"></span> {{ formErrors.name }}</span>
            </div>
            <div class="col-xl-6 mt-3">
                <label>Phone <span class="required-field">*</span></label>
                <input type="text" class="univ-input" v-model="formReq.phone_number" />
                <span class="error-field" v-if="formErrors.phone_number"><span class="icon-warning"></span> {{ formErrors.phone_number }}</span>
            </div>
        </div>
        <div class="form-group mt-3">
            <label>Email <span class="required-field">*</span></label>
            <input type="email" class="univ-input" v-model="formReq.email" />
            <span class="error-field" v-if="formErrors.email"><span class="icon-warning"></span> {{ formErrors.email }}</span>
        </div>
        <div class="form-group mt-3">
            <label>Message</label>
            <textarea v-model="formReq.message" class="univ-input" rows="4"></textarea>
        </div>
        <input type="hidden" class="univ-input" v-model="formReq.source" />
        <div class="form-group row justify-content-center">
            <div class="col-xl-3">
                <button class="univ-btn w-100 mt-2" @click="formSubmit()" > <b-spinner v-if="loader" small></b-spinner> Submit</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import api from "@/services/api";
export default {
    name: "ContactForm",
    props:{
        requestData:{
            required:true,
        },
        textMsg:{
            required:true,
        },
    },
    data(){
        return {
            formReq:{
                name:this.requestData.name,
                email: this.requestData.email,
                phone_number:this.requestData.phone_number,
                message: this.textMsg,
                source:this.requestData.source,
            },
            formErrors: {
                name:"",
                email: "",
                phone_number:"",
            },
            loader:false,
        }
    },
    methods: {
        formSubmit(){
            let app = this;
            app.clearedError();
            app.loader = true;
            axios.post(api.getUrl('/send-contact'),app.formReq).then((response) => {
                if(response.data.success == true){
                    app.loader = false;
                    app.$toast.success(response.data.message,{position: "top-center",timeout: 2000});
                }else{
                    if(response.data.message){
                        app.loader = false;
                        app.$toast.error(response.data.message,{position: "top-center",timeout: 2000});
                    }else if(response.data.errors.length > 0){

                        app.loader = false;
                        response.data.errors.forEach(function(item) {
                            if(item.key == "name"){
                                app.formErrors.name = item.message;
                            }else if(item.key == "email"){
                                app.formErrors.email = item.message;
                            }else if(item.key == "phone_number"){
                                app.formErrors.phone_number = item.message;
                            }
                        }); 
                    }
                }
            });
        },
        clearedError(){
            let app = this;
            app.formErrors.name         = "";
            app.formErrors.email        = "";
            app.formErrors.mobile_number= "";
        },
    },
    watch:{
        textMsg(value){
            this.formReq.message = value;
        }
    }
}
</script>
