<template>
    <div v-if="left_menus.length > 0">
        <header>
            <div class="container">
                <div class="header-wrapper">
                    <div class="header-middle-state">
                        <nav class="site-menu-left nav-menu-state">
                            <ul>
                                <li>
                                    <router-link to="/">Home</router-link>
                                </li>
                                <template v-for="(item, i) in left_menus">
                                    <li v-if="item.children" :key="i" class="has-submenu">
                                        <router-link :to="item.slug">{{ item.title }} <i
                                                class="fas fa-chevron-down"></i></router-link>
                                        <div>
                                            <ul>
                                                <li v-for="(sub_item, j) in item.children[0]" :key="j">
                                                    <router-link :to="sub_item.slug">{{ sub_item.title }}</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li v-else :key="'A' + i">
                                        <router-link :to="item.slug"> {{ item.title }} </router-link>
                                    </li>
                                </template>
                            </ul>
                        </nav>
                        <div class="mobile-top-hammenu">
                            <div class="ham-menu-mobile">
                                <div class="nav-end">
                                    <div class="fhamburger-parent">
                                        <div :class="['fhamburger', { 'open': isMenuOpen }]" @click="toggleMenuOpen">
                                            <span class="fhamburger-box">
                                                <span class="fhamburger-inner"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <nav class="nav-menu-state-mobile" :class="{ 'navRespo': isMenuOpen }">
                                <div class="sidebarClose d-flex justify-content-end mb-3"><span class="closeModal"
                                        @click="toggleMenuClose"><span class="icon-close"></span></span></div>
                                <ul>
                                    <li>
                                        <router-link to="/">Home</router-link>
                                    </li>
                                    <template v-for="(item, i) in left_menus">
                                        <li v-if="item.children" :key="'M'+i" class="has-submenu">
                                            <router-link :to="item.slug">{{ item.title }} <i
                                                    class="fas fa-chevron-down"></i></router-link>
                                            <div>
                                                <ul>
                                                    <li v-for="(sub_item, j) in item.children[0]" :key="j">
                                                        <router-link :to="sub_item.slug">{{ sub_item.title }}</router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li v-else :key="'MO' + i">
                                            <router-link :to="item.slug"> {{ item.title }} </router-link>
                                        </li>
                                    </template>
                                    <li v-for="(item_k, k) in right_menus" :key="k">
                                        <router-link :to="item_k.slug">{{ item_k.title }}</router-link>
                                    </li>
                                    <li v-if="isAuthenticated" @click="logoutAction" class="profile-list-child">
                                        <i class="fa fa-sign-out"></i> Logout
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="logo">
                            <img :src="header_logo" class="img-fluid">
                        </div>
                        <nav class="site-menu-right nav-menu-state" v-if="right_menus.length > 0">
                            <ul>

                                <li v-for="(item_k, k) in right_menus" :key="k">
                                    <router-link :to="item_k.slug">{{ item_k.title }}</router-link>
                                </li>
                                <li v-if="!isAuthenticated">
                                    <a href="javascript:void(0);" @click="loginAction('login-btn')" v-b-modal.authModal>Login</a>
                                </li>
                                <li v-if="isAuthenticated">
                                    <router-link :to="{ name: 'UserPropertyFavourite' }" class="wishlist-btn" v-b-tooltip title="Favourites"><span class="icon-wishlist"></span><span class="count-wishlist">{{ wishlistCount }}</span></router-link>
                                </li>
                                <li v-else>
                                    <a href="javascript:void(0);" @click="loginAction('login-btn')" v-b-modal.authModal class="wishlist-btn" v-b-tooltip title="Favourites" ><span class="icon-wishlist"></span><span class="count-wishlist">{{ wishlistCount }}</span></a>
                                </li>
                                <li v-if="isAuthenticated" class="profile-has-submenu profile-wrapper">
                                    <div class="profile-icon-down">
                                        <img :src="avatar_path">
                                    </div>
                                    <div class="profile-childmenu">
                                        <ul>
                                            <li class="profile-list-child">
                                                <router-link :to="{ name: 'Profile' }"><i class="fa fa-user"></i>
                                                    Profile</router-link>
                                            </li>
                                            <li class="profile-list-child">
                                                <router-link :to="{ name: 'BoostProperty' }"><i class="fa fa-user"></i> Boost Property</router-link>
                                            </li>
                                            <li @click="logoutAction" class="profile-list-child">
                                                <i class="fa fa-sign-out"></i> Logout
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li v-if="isAuthenticated">
                                    <a href="javascript:void(0);" class="sell-btn" @click="addProperty" ><i class="fas fa-plus"></i> Sell Property</a>
                                </li>
                                <li v-else>
                                    <a href="javascript:void(0);" class="sell-btn" @click="loginAction('sell-property-btn')"
                                        v-b-modal.authModal>Sell Property</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
        <div class="mob-nav-down">
            <div class="float-nav-mobile">
                <ul>
                    <li>
                        <router-link to="/">
                            <div class="btn-home-icon"><span class="icon-home"></span></div>
                        </router-link>
                    </li>
                    <li v-if="isAuthenticated">
                        <router-link :to="{ name: 'UserPropertyFavourite' }"><div class="wishlist-mob"><span class="icon-wishlist"></span></div></router-link>
                    </li>
                    <li v-else>
                        <a href="javascript:void(0);" @click="loginAction('login-btn')" v-b-modal.authModal ><div class="wishlist-mob"><span class="icon-wishlist"></span></div></a>
                    </li>
                    <li v-if="isAuthenticated">
                        <router-link :to="{ name: 'Profile' }">
                            <div class="profile-btn">
                                <div class="profile-icon-down">
                                    <img :src="avatar_path">
                                </div>
                            </div>
                        </router-link>
                    </li>
                    <li v-else>
                        <div class="profile-btn" @click="loginAction('login-btn')" v-b-modal.authModal><span class="icon-user"></span></div>
                    </li>
                    <li v-if="isAuthenticated">
                        <a href="javascript:void(0);" class="sell-btn-mob" @click="addProperty" ><i class="fas fa-plus"></i> Sell Property</a>
                    </li>
                    <li v-else>
                        <a href="javascript:void(0);" class="sell-btn-mob" @click="loginAction('sell-property-btn')" v-b-modal.authModal>Sell Property</a>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>
<script>
// import { Slide } from 'vue-burger-menu' 
import axios from 'axios'
import api from '@/services/api'
import store from "@/store"
import Helper from "@/services/helper"
export default {
    name: 'SiteHeader',
    components: {
        // Slide
    },
    data() {
        return {
            userHeaderScroll: {
                vuescroll: {
                    mode: "native",
                    wheelScrollDuration: "700",
                },
                scrollPanel: {
                    maxHeight: 300,
                    speed: 2000,
                },
                bar: {
                    background: "#ddd",
                },
            },
            header_logo: "",
            left_menus: [],
            right_menus: [],
            isAuthenticated: store.getters.authenticated,
            user: store.getters.user,
            avatar_path: store.getters.user.avatar_path,
            isMenuOpen: false,
            wishlistCount: store.getters.userWishlist,
        }
    },
    mounted() {
        let app = this;
        app.renderHeaderInfo();
        app.$root.$on("profile-update", ($event) => {
            app.avatar_path = $event.avatar_path;
        });
        app.$root.$on("wishlist-count", ($event) => {
            app.wishlistCount = $event;
        });
    },
    methods: {
        toggleMenuOpen() {
            this.isMenuOpen = true;
        },
        toggleMenuClose() {
            this.isMenuOpen = false;
        },
        loginAction(source) {
            store.commit("setLoginSource", source);
        },
        async renderHeaderInfo() {
            let app = this;
            await axios.get(api.getUrl('/header-info')).then(function (response) {
                if (response.data.success) {
                    app.header_logo = response.data.data.header_logo;
                    app.left_menus = response.data.data.left_menus;
                    app.right_menus = response.data.data.right_menus;
                }
            });
        },
        logoutAction() {
            let app = this;
            store.commit("setUserWishlist",0);
            app.wishlistCount= store.getters.userWishlist;
            Helper.logout();
            app.isAuthenticated = false;
            app.$router.push({ path: '/' });
        },
        addProperty(){
            let app = this;
            app.$root.$emit("property-slug", "");
            setTimeout(() => {
                app.$bvModal.show('addPropertyModal');
            }, 500);
        },
    },
    watch: {
        $route() {
            if (store.getters.authenticated) {
                this.isAuthenticated = true;
                this.user = store.getters.user;
                this.avatar_path = store.getters.user.avatar_path;
            } else {
                this.isAuthenticated = false;
            }
            this.wishlistCount = store.getters.userWishlist.length;
            this.toggleMenuClose();
            this.wishlistCount= store.getters.userWishlist;
        }
    }
}
</script>