<template>
  <div id="app">
    <SiteHeader />
    <router-view/>
    <SiteFooter />

    <!-- Login Register Pop Up -->
    <AuthModal />
    <ProfileModal/>
  </div>
</template>
<script>
import SiteHeader from './components/SiteHeader.vue';
import SiteFooter from './components/SiteFooter.vue';
import AuthModal from './components/AuthModal.vue';
import ProfileModal from './components/ProfileModal.vue';

export default {
  components:{
    SiteHeader,
    SiteFooter,
    AuthModal,
    ProfileModal
}
}
</script>
<style>
#app {
  font-family: 'Poppins', sans-serif;
}
</style>
