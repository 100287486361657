<template>
   
    <div class="profileForm">
        <div class="form-group mb-3">
            <label>Full Name <span class="required-field">*</span></label>
            <input type="text" v-model="user.name"  class="univ-input">
            <span class="required-field" v-if="updateErrors.name">{{ updateErrors.name }}</span>
        </div>
        <div class="form-group mb-3">
            <label>Contact No. <span class="required-field">*</span></label>
            <input type="number" v-model="user.mobile_number" class="univ-input">
            <span class="required-field" v-if="updateErrors.mobile_number">{{ updateErrors.mobile_number }}</span>
        </div>
        <div class="form-group mb-3">
            <label>Address <span class="required-field">*</span></label>
            <input type="text" v-model="user.address" class="univ-input">
            <span class="required-field" v-if="updateErrors.address">{{ updateErrors.address }}</span>
        </div>
        <div class="form-group mb-3">
            <div class="image-section" style=" text-align: center; " v-if="image">
                <img class="preview-img" :src="generateURL(image)" />
            </div>
            <div class="image-section" style=" text-align: center; " v-else>
                <img class="preview-img" :src="user.avatar_path" />
            </div>
            <div class="file-input upload-btn">
                <input type="file" name="file-input" id="file-input" class="file-input__input" @change="onImageChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />
                <label class="file-input__label" for="file-input"> <span class="icon-upload"></span> <span>Upload Image</span></label>
            </div>
        </div>

        <div class="form-group d-flex align-items-center mb-3">
            <label class="switch mb-0 me-2">
                <input type="checkbox" v-model="change_pw">
                <span class="slider round"></span>
            </label>
            <p class="mb-0">Password change</p>
        </div>
        <div class="form-group row" v-if="change_pw">
            <div class="col-xl-6 mb-3">
                <label>Old Password <span class="required-field">*</span></label>
                <input type="text" v-model="user.old_password" class="univ-input">
                <span class="required-field" ></span>
            </div>
            <div class="col-xl-6 mb-3" >
                <label>New Password <span class="required-field">*</span></label>
                <input type="text" v-model="user.new_password" class="univ-input">
                <span class="required-field" ></span>
            </div>
        </div>
        <div class="form-group">
            <span class="required-field" v-if="errorMessage && change_pw">{{ errorMessage }}</span>
            <button class="univ-btn w-100" @click="formSubmit" ><b-spinner v-if="loginLoader" small></b-spinner> Update</button>
        </div>
    </div>  
    
</template>
<script>
import axios from "axios";
import store from "@/store";
import api from '@/services/api';

export default {
    data() {
        return {
            user:{
                id: store.getters.user.id,
                name: store.getters.user.name,
                mobile_number: store.getters.user.mobile_number,
                address: store.getters.user.address,
                avatar: store.getters.user.avatar,
                avatar_path: store.getters.user.avatar_path,
                new_avatar:"",
                old_password:"",
                new_password:"",
                pw_option:'disabled',
            },
            change_pw:false,
            image:"",
            loginLoader: false,
            errorMessage:"",
            updateErrors: {
                name:"",
                mobile_number:"",
                address: "",
            },
        };
    },
    methods: {
        closeModal() {
            let app = this;
            app.$bvModal.hide('profileModal');
        },
        onImageChange(e){
            let app = this;
            app.image = e.target.files[0];
        },
        formSubmit(e) {
            e.preventDefault();
            let app = this;
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            app.loginLoader = true;
            app.user.new_avatar = app.image;
            if(app.change_pw){
                app.user.pw_option = "enabled";
            }else{
                app.user.pw_option = "disabled";
            }
            axios.post(api.getUrl('/user/update'), app.user, config).then(function (response) {
                if(response.data.success == true){
                    app.closeModal();
                    app.loginLoader = true;
                    store.commit("setUserData",response.data.data);
                    app.$root.$emit('profile-update',response.data.data);
                }else{
                    app.loginLoader = false;
                    if(response.data.message){
                        app.loginLoader = false;
                        app.errorMessage = response.data.message;
                    }else if(response.data.errors.length > 0){
                        app.loginLoader = false;
                        response.data.errors.forEach(function(item) {
                            if(item.key == "name"){
                                app.updateErrors.name = item.message;
                            }else if(item.key == "mobile_number"){
                                app.updateErrors.mobile_number = item.message;
                            } else if(item.key == "address"){
                                app.updateErrors.address = item.message;
                            }
                        }); 
                    }
                }
            });
        },
        generateURL(file) {
            let fileSrc = URL.createObjectURL(file);
            setTimeout(() => {
                URL.revokeObjectURL(fileSrc);
            }, 1000);
            return fileSrc;
        },
    },
}
</script>
<style src="../../../assets/css/property.css"></style>
<style src="../../../assets/css/dropfile.css"></style>