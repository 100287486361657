import store from "@/store";
import Helper from "@/services/helper";
const userRoutes = [
    {
      path: '/dashboard',
      name: 'Dashboard',
      beforeEnter: requiredAuthentication,
      component: () => import('@/views/Users/Dashboard'),
    },
    {
      path: '/user/profile',
      name: 'Profile',
      beforeEnter: requiredAuthentication,
      component: () => import('@/views/Users/Profile'),
    },
    {
      path: '/user/property/add',
      name: 'AddUserProperty',
      beforeEnter: requiredAuthentication,
      component: () => import('@/views/Users/AddProperty/AddUserProperty'),
    },
    {
      path: '/user/property/:slug/edit',
      name: 'EditUserProperty',
      beforeEnter: requiredAuthentication,
      component: () => import('@/views/Users/AddProperty/EditUserProperty'),
    },
    {
      path: '/user/property/favourites',
      name: 'UserPropertyFavourite',
      beforeEnter: requiredAuthentication,
      component: () => import('@/views/Users/UserPropertyFavourite'),
    },
    {
      path: '/user/property/boost',
      name: 'BoostProperty',
      beforeEnter: requiredAuthentication,
      component: () => import('@/views/Users/BoostProperty'),
    },
    {
      path: '/user/property/boost/fail',
      name: 'BoostPropertyFail',
      beforeEnter: requiredAuthentication,
      component: () => import('@/views/Users/BoostProperty/ResponseFail'),
    },
    {
      path: '/user/property/boost/success',
      name: 'BoostPropertySuccess',
      beforeEnter: requiredAuthentication,
      component: () => import('@/views/Users/BoostProperty/ResponseSuccess'),
    },
    {
      path: "/logout",
      name: "Logout",
      beforeEnter: (to, from, next) => {
        Helper.logout()
        next({
          path: "/"
        })
      }
    },
]
function requiredAuthentication(to, from, next){
  if(store.getters.accessToken && store.getters.authenticated){
    return next();
  }
  return next('/');
}
export default userRoutes;