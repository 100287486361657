<template>
<div v-if="resetPasswordModal">
    <ResetPassword :email="formReq.email" @reset-password-modal="handleResetPasswordModal($event)" />
</div>
<div class="forgot-password" v-else>
    <a class="back-btn" @click="backEvent()">
        <div class="btn-back-wrapper">
            <div class="left-angle"></div>
        </div>
    </a>
    <div class="form-group d-flex justify-content-center mb-3">
        <div class="btn-key-wrapper">
            <div class="key"></div>
        </div>
    </div>
    <div class="form-group mb-3">
        <h2>Forgot your password?</h2>
        <p>Don't worry! Just fill in your email and we'll help you reset your password.</p>
    </div>
    <div class="form-group mb-3">
        <label>Email <span class="required-field">*</span></label>
        <input type="text"  class="univ-input" v-model="formReq.email">
        <span class="error-field" v-if="reqErrors.email"><span class="icon-warning"></span> {{ reqErrors.email }}</span>
    </div>
    <div class="form-group">
        <span class="error-message" v-if="reqErrors.message"><span class="icon-warning"></span> {{ reqErrors.message }}</span>
        <button class="univ-btn w-100" @click="sendOTP()" > <b-spinner v-if="loginLoader" small></b-spinner> Submit</button> 
    </div>
</div>  
</template>
<script>
import axios from 'axios';

import api from '@/services/api';
import ResetPassword from "@/views/Signin/ResetPassword";
export default {
    components:{
        ResetPassword,
    },
    data() {
        return {
            formReq: {
                email: "",
            },
            reqErrors: {
                email:"",
                message:"",
            },
            loginLoader: false,
            resetPasswordModal:false,
        };
    },
    methods: {
        sendOTP(){
            let app = this;
            app.clearedError();
            app.loginLoader = true;
            axios.post(api.getUrl('/forgot-password'),app.formReq).then((response) => {
                if(response.data.success == true){
                    app.loginLoader = false;
                    app.resetPasswordModal = true;
                }else{
                    if(response.data.message){

                        app.loginLoader = false;
                        app.reqErrors.message = response.data.message;

                    }else if(response.data.errors.length > 0){

                        app.loginLoader = false;
                        response.data.errors.forEach(function(item) {
                            if(item.key == "email"){
                                app.reqErrors.email = item.message;
                            }
                        }); 
                    }
                }
            });
        },
        clearedError(){
            let app = this;
            app.reqErrors.email       = "";
            app.reqErrors.message     = "";
        },
        backEvent(){
            let app = this;
            app.$root.$emit("redirect-to-login", false);
        },
        handleResetPasswordModal($event){
            let app = this;
            app.resetPasswordModal = $event;
        },
    }
}
</script>