import Vue from 'vue'
import VueRouter from 'vue-router'
import userRoutes from './user'

Vue.use(VueRouter)

const generalRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Homepage/Home.vue'),
    meta: {
        title: 'KJ RealState'
    }
  },
  {
    path: '/search/:',
    redirect: to => {
      return { 
        name: 'SearchListing',
        query: { 
          purpose: to.params.purpose,
          keywords: to.params.keywords,
          category: to.params.category,
          location: to.params.location,
          type: to.params.type,
          sort: to.params.sort,
        },
      }
    },
    component: () => import('../views/PropertyListing/SearchListing.vue'),
    props: true
  },
  {
    path: '/search',
    name: 'SearchListing',
    component: () => import('../views/PropertyListing/SearchListing.vue')
  },
  {
    path: '/properties',
    name: 'PropertyListing',
    component: () => import('../views/PropertyListing/PropertyListing.vue')
  },
  {
    path: '/properties/:slug',
    name: 'AdsPropertyListing',
    component: () => import('../views/PropertyListing/AdsPropertyListing.vue')
  },
  {
    path: '/detail/:slug',
    name: 'PropertyDetail',
    component: () => import('../views/PropertyDetail/PropertyDetail.vue')
  },
  {
    path: '/add-property',
    name: 'AddProperty',
    component: () => import('../views/AddProperty/AddProperty.vue')
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: () => import('../views/General/ContactPage.vue')
  },
  {
    path: '/:slug',
    name: 'DefaultPage',
    component: () => import('../views/General/DefaultPage.vue'),
  },
  
]

const routes = generalRoutes.concat(userRoutes);
const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    } else {
        return { x: 0, y: 0 }
    }
},
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  const title = to.meta.title
  //Take the title from the parameters
  const titleFromParams = to.params.pageTitle;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title
  }
  // If we have a title from the params, extend the title with the title
  // from our params
  if (titleFromParams) {
    document.title = `${titleFromParams} - ${document.title}`;
  }
  // Continue resolving the route
  next()
})
export default router
