<template>
<div>
    <footer class="siteFooter">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="footer-logo">
                        <img :src="footer_logo" class="img-fluid">
                    </div>
                </div>
                <div class="col-xl-7">
                    <div class="footer-content text-center">
                        <p>{{ footer_description }}</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="quickLink" v-if="footer_menus.length > 0">
                        <ul>
                            <li v-for="(item,i) in footer_menus" :key="i">
                                <router-link :to="item.slug">{{ item.title }}</router-link>
                            </li>
                            <li v-if="isAuthenticated">
                                <a href="javascript:void(0);" @click="logoutAction" ><i class="fa fa-sign-out"></i> Logout</a> 
                            </li>
                            <li v-else>
                                <a href="javascript:void(0);" @click="loginAction('login-btn')"  v-b-modal.authModal>Login / Register</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12">
                    <div class="social-link text-center">
                        <h4>Follow us on:</h4>
                        <ul>
                            <li>
                                <a :href="facebook" v-b-tooltip.hover title="Facebook" target="_blank" ><i class="fab fa-facebook"></i></a>
                            </li>
                            <li>
                                <a :href="instagram" v-b-tooltip.hover title="Instagram" target="_blank"><i class="fab fa-instagram"></i></a>
                            </li>
                            <li>
                                <a :href="tiktok" v-b-tooltip.hover title="Tiktok" target="_blank"><i class="fab fa-tiktok"></i></a>
                            </li>
                            <li>
                                <a :href="youtube" v-b-tooltip.hover title="Youtube" target="_blank"><i class="fab fa-youtube"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="copy-right text-center" v-html="site_copyright"></div>
                </div>
            </div>
        </div>
    </footer>
    <DetailQuickView v-if="quickViewStatus" :propertyData="propertyData" />
    <AddPropertyModal :propertySlug="propertySlug" />
</div>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import store from "@/store"
import Helper from "@/services/helper"
import DetailQuickView from './DetailQuickView'
import AddPropertyModal from './AddPropertyModal'
export default {
    name:'Footer',
    components:{DetailQuickView,AddPropertyModal},
    data() {
      return {
        footer_logo:"",
        footer_description:"",
        footer_menus:[],
        facebook:"",
        instagram:"",
        tiktok:"",
        youtube:"",
        site_copyright:"",
        isAuthenticated:store.getters.authenticated,
        propertyData:"",
        quickViewStatus:false,
        propertySlug:"",
      }
    },
    mounted() {
        let app = this;
        app.renderHeaderInfo();
        app.$root.$on("property-data", ($event) => {
            app.propertyData = $event;
        });
        app.$root.$on("quickview-status", ($event) => {
            app.$bvModal.show('DetailQuickView');
            app.quickViewStatus = $event;
        });
        app.$root.$on("property-slug", ($event) => {
            app.propertySlug = $event;
        });
    },
    methods: {
        async renderHeaderInfo(){
            let app = this;
            await axios.get(api.getUrl('/footer-info')).then(function (response) {
                if(response.data.success){
                    app.footer_logo         = response.data.data.footer_logo;
                    app.footer_description  = response.data.data.footer_description;
                    app.footer_menus        = response.data.data.footer_menu;
                    app.facebook    = response.data.data.social_media.facebook;
                    app.instagram   = response.data.data.social_media.instagram;
                    app.tiktok      = response.data.data.social_media.tiktok;
                    app.youtube     = response.data.data.social_media.youtube;
                    app.site_copyright      = response.data.data.site_copyright;
                }
            });
        },
        logoutAction(){
            let app = this;
            Helper.logout();
            app.isAuthenticated = false;
            app.$router.push({path:'/'}); 
        },
        loginAction(source) {
            store.commit("setLoginSource", source);
        },
    },
    watch:{
        $route (){
            if(store.getters.authenticated){
                this.isAuthenticated = true;
            }else{
                this.isAuthenticated = false;
            }
        }
    } 
}
</script>