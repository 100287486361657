<template>
    <div>
        <b-modal id="profileModal" centered>
            <div class="modalTitle">
                <h2>Profile</h2>
                <span class="closeModal"  @click="$bvModal.hide('profileModal')">
                    <span class="icon-close"></span>
                </span>
            </div>
            <div class="modalContent pt-3">
                <b-tabs content-class="mt-3">
                   <ProfileForm/>
                </b-tabs>
            </div>
        </b-modal>
    </div>
</template>
<script>
import ProfileForm from "@/views/Users/Profile/ProfileEdit"
export default {
    name: 'ProfileModal',
    components:{
        ProfileForm
    },
    data() {
        return {
            showModal: true,
        };
    },
    methods: {
        closeModal() {
            this.$bvModal.hide('profileModal')
        },
    }
}
</script>