<template>
<div class="loginForm">
    <div class="form-group mb-3">
        <label>Email <span class="required-field">*</span></label>
        <input type="text"  class="univ-input" v-model="logingReq.email">
        <span class="error-field" v-if="loginErrors.email"><span class="icon-warning"></span> {{ loginErrors.email }}</span>
    </div>
    <div class="form-group mb-3">
        <label>Password <span class="required-field">*</span></label>
        <input type="password"  class="univ-input" v-model="logingReq.password" >
        <span class="error-field" v-if="loginErrors.password"><span class="icon-warning"></span> {{ loginErrors.password }}</span>
    </div>
    <div class="form-group text-end mb-3">
        <span class="error-message" v-if="loginErrors.message"><span class="icon-warning"></span> {{ loginErrors.message }}</span>
        <p>Lost your Password? <span class="brand-color text-cursor" @click="forgotPassword()" >Click Here</span></p>
    </div>
    <div class="form-group">
        <button class="univ-btn w-100" @click="userLogin()" > <b-spinner v-if="loginLoader" small></b-spinner> Sign In</button>
    </div>
</div>  

</template>
<script>
import axios from 'axios';
import store from "@/store";

import api from '@/services/api';
import helper from "@/services/helper";
export default {
    data() {
        return {
            logingReq: {
                email: "",
                password: "",
            },
            loginErrors: {
                email:"",
                password: "",
                message:"",
            },
            loginLoader: false,
        };
    },
    methods: {
        closeModal() {
            let app = this;
            app.$bvModal.hide('authModal');
        },
        userLogin(){
            let app = this;
            app.clearedError();
            app.loginLoader = true;
            axios.post(api.getUrl('/login'),app.logingReq).then((response) => {
                if(response.data.success == true){
                    app.closeModal();
                    app.loginLoader = false;

                    store.commit("setAuthentication", true);
                    helper.setLoginDetails(response.data);
                    store.commit("setUserWishlist",response.data.favourites_count);
                    app.$root.$emit('wishlist-count',store.getters.userWishlist);
                }else{
                    if(response.data.message){

                        app.loginLoader = false;
                        app.loginErrors.message = response.data.message;

                    }else if(response.data.errors.length > 0){

                        app.loginLoader = false;
                        response.data.errors.forEach(function(item) {
                            if(item.key == "email"){
                                app.loginErrors.email = item.message;
                            } else if(item.key == "password"){
                                app.loginErrors.password = item.message;
                            }
                        }); 
                    }
                }
            });
        },
        clearedError(){
            let app = this;
            app.loginErrors.email       = "";
            app.loginErrors.password    = "";
            app.loginErrors.message     = "";
        },
        forgotPassword(){
            let app = this;
            app.$emit('forgot-password-modal', true);
        },
        handleForgotPassword($event){
            let app = this;
            app.passwordForgotModal = $event;
        },
    }
}
</script>