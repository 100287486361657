<template>
<b-modal id="addPropertyModal" centered class="w-75">
    <div class="modalTitle">
        <h2>Welcome to KJ Real State </h2>
        <span class="closeModal"  @click="$bvModal.hide('addPropertyModal')">
            <span class="icon-close"></span>
        </span>
    </div>
    <div class="modalContent p-3">
        <div class="addForms">
            <template v-if="step === 1">
                <h3>Basic Information</h3>
                <div class="form-group row">
                    <div class="col-12 mb-3">
                        <input v-model="formRequest.title" :maxlength="titleMaxLength" @input="updateTitleCount" type="text" class="univ-input" placeholder="Property Title">
                        <span class="text-counter">{{ titleCounter }}/{{ titleMaxLength }}</span>
                        <span class="error-field" v-if="formRequestError.title && formRequest.title ==''"><span class="icon-warning"></span> {{ formRequestError.title }}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-12 mb-3">                                
                        <LocationAutocomplete :address="selecteAddress" @selected-location="selectedLocationHandle($event)" />
                        <span class="error-field" v-if="formRequestError.address && formRequest.address ==''"><span class="icon-warning"></span> {{ formRequestError.address }}</span>
                    </div>
                    <div class="col-xl-6 mb-3">                                
                        <select class="univ-input" v-model="formRequest.location_id" @change="selecteDistrict($event)">
                            <option value="">District</option>
                            <option v-for="(item,n) in location" :key="n" :value="item.id">{{ item.name }}</option>
                        </select>
                        <span class="error-field" v-if="formRequestError.location_id && formRequest.location_id ==''"><span class="icon-warning"></span> {{ formRequestError.location_id }}</span>
                    </div>
                    <div class="col-xl-6 mb-3">                                
                        <date-picker  v-model="formRequest.expire_date" placeholder="Expiry Date" :disabled-date="(date) => date <= new Date(new Date().setDate(new Date().getDate()))" valueType="format"></date-picker>
                    </div>
                    <div class="col-12 mb-3">
                        <textarea v-model="formRequest.description" :maxlength="textMaxLength" @input="updateTextCount" class="univ-input" rows="4" placeholder="Description"></textarea>
                        <span class="text-counter">{{ textCounter }}/{{ textMaxLength }}</span>
                        <span class="error-field" v-if="formRequestError.description && formRequest.description ==''"><span class="icon-warning"></span> {{ formRequestError.description }}</span>
                    </div>
                    <div class="col-12 mb-3">
                        <textarea v-model="formRequest.land_mark" class="univ-input" rows="4" placeholder="Landmark"></textarea>
                    </div>
                </div>
            </template>

            <template v-if="step === 2">
                <h3>Categories</h3>
                <div class="form-group row">
                    <div class="col-xl-6 mb-3">
                        <multiselect
                            placeholder="Select Purpose"
                            v-model="purpose"
                            :options="property_purpose"
                            :custom-label="purposeLabel"
                            @select="getSelectedPurpose"
                            @remove="removedSelectedPurpose"
                        >
                        </multiselect>
                        <span class="error-field" v-if="formRequestError.purpose && formRequest.purpose ==''"><span class="icon-warning"></span> {{ formRequestError.purpose }}</span>
                    </div>
                    <div class="col-xl-6 mb-3">
                        <multiselect
                            placeholder="Select Category"
                            v-model="category"
                            :options="property_category"
                            :custom-label="categoryLabel"
                            @select="getSelectedCategory"
                            @remove="removedSelectedCategory"
                        >
                        </multiselect>
                        <span class="error-field" v-if="formRequestError.category_id && formRequest.category_id ==''"><span class="icon-warning"></span> {{ formRequestError.category_id }}</span>
                    </div>
                    <div class="col-xl-12 mb-3">
                        <multiselect
                            placeholder="Select Type"
                            v-model="types"
                            :options="category_types"
                            :custom-label="typeLabel"
                            @select="getSelectedType"
                            @remove="removedSelectedType"
                        >
                        </multiselect>
                        <span class="error-field" v-if="formRequestError.category_type_id && formRequest.category_type_id ==''"><span class="icon-warning"></span> {{ formRequestError.category_type_id }}</span>
                    </div>
                </div>
            </template>

            <template v-if="step === 3">
                <h3>Specifications</h3>
                <div class="form-group row">
                    <div class="col-xl-12 mb-3">                                
                        <select class="univ-input" v-model="formRequest.land_size_type" @change="landSizeChange" >
                            <option value="">Select Land Size</option>
                            <option value="rapd"> Ropani/Anna/Paisa/Dam </option>
                            <option value="bkd"> Bigha/Katha/Dhur </option>
                        </select>
                        <span class="error-field" v-if="formRequestError.road_size && formRequest.road_size ==''"><span class="icon-warning"></span> {{ formRequestError.road_size }}</span>
                    </div>
                    <template v-if="formRequest.land_size_type=='rapd'">
                        <div class="col-xl-3 mb-3">                                
                            <input type="number" min="1" v-model="formRequest.ropani" class="univ-input" placeholder="Ropani" >
                        </div>
                        <div class="col-xl-3 mb-3">                                
                            <input v-model="formRequest.anna" type="number" min="1" class="univ-input" placeholder="Anna" >
                        </div>
                        <div class="col-xl-3 mb-3">                                
                            <input v-model="formRequest.paisa" type="number" min="1" class="univ-input" placeholder="Paisa" >
                        </div>
                        <div class="col-xl-3 mb-3">                                
                            <input v-model="formRequest.dam" type="number" min="1" class="univ-input" placeholder="Dam" >
                        </div>
                    </template>
                    <template v-if="formRequest.land_size_type=='bkd'" >
                        <div class="col-xl-4 mb-3">                                
                            <input v-model="formRequest.bigha" type="number" min="1" class="univ-input" placeholder="Bigha" >
                        </div>
                        <div class="col-xl-4 mb-3">                                
                            <input v-model="formRequest.katha" type="number" min="1" class="univ-input" placeholder="Katha" >
                        </div>
                        <div class="col-xl-4 mb-3">                                
                            <input v-model="formRequest.dhur" type="number" min="1" class="univ-input" placeholder="Dhur" >
                        </div>
                    </template>

                    <div class="col-xl-4 mb-3">                                
                        <select class="univ-input" v-model="formRequest.road_size">
                            <option value="">Road Size</option>
                            <option value="Less Than 5 ft.">Less Than 5 ft.</option>
                            <option value="5-8 ft.">5 - 8 ft.</option>
                            <option value="9-12 ft.">9 - 12 ft.</option>
                            <option value="13-20 ft.">13 - 20 ft.</option>
                            <option value="Above 20 ft.">Above 20 ft.</option>
                            <option value="Goreto"> Goreto </option>
                            <option value="No Road "> No Road </option>
                        </select>
                        <span class="error-field" v-if="formRequestError.road_size && formRequest.road_size ==''"><span class="icon-warning"></span> {{ formRequestError.road_size }}</span>
                    </div>
                    <div class="col-xl-4 mb-3">                                
                        <select class="univ-input" v-model="formRequest.road_type">
                            <option value="">Road Type</option>
                            <option value="Pitched">Pitched</option>
                            <option value="Gravel">Gravel</option>
                            <option value="Soil (Mato)">Soil (Mato)</option>
                            <option value="Not Road">Not Road</option>
                            <option value="Others">Others</option>
                        </select>
                        <span class="error-field" v-if="formRequestError.road_type && formRequest.road_type ==''"><span class="icon-warning"></span> {{ formRequestError.road_type }}</span>
                    </div>
                    <div class="col-xl-4 mb-3">
                        <date-picker  v-model="formRequest.build_date" placeholder="Built Year" format="YYYY-MM" type="month" :disabled-date="(date) => date >= new Date(new Date().setDate(new Date().getDate()))" valueType="format"></date-picker>
                    </div>

                    <template v-if="category !=='' && selectedCategoryName !=='Land' ">
                        <div class="col-xl-4 mb-3">
                            <input v-model="formRequest.floor" type="number" min="1" class="univ-input" placeholder="Floor">
                        </div>
                        <div class="col-xl-4 mb-3">
                            <input v-model="formRequest.bedroom" type="number" min="1" class="univ-input" placeholder="Bedroom">
                        </div>
                        <div class="col-xl-4 mb-3">
                            <input v-model="formRequest.bathroom" type="number" min="1" class="univ-input" placeholder="Bathroom">
                        </div>
                        <div class="col-xl-4 mb-3">
                            <input v-model="formRequest.livingroom" type="number" min="1" class="univ-input" placeholder="Living Room">
                        </div>
                        <div class="col-xl-4 mb-3">
                            <input v-model="formRequest.kitchen" type="number" min="1" class="univ-input" placeholder="Kitchen">
                        </div>
                        <div class="col-xl-4 mb-3">                                
                            <select class="univ-input" v-model="formRequest.furnishing" required>
                                <option value="">Furnishing</option>
                                <option value="None">None</option>
                                <option value="Full">Full</option>
                                <option value="Semi">Semi</option>
                            </select>
                        </div>
                    </template>
                
                    <div class="col-xl-4 mb-3">                                 
                        <input v-model="formRequest.buildup" type="number" min="0" class="univ-input" placeholder="Buildup" >
                        <span class="error-field" v-if="formRequestError.buildup && formRequest.buildup ==''"><span class="icon-warning"></span> {{ formRequestError.buildup }}</span>
                    </div>
                    <div class="col-xl-4 mb-3">                                
                        <select class="univ-input" v-model="formRequest.area_type">
                            <option value="">Select Unit</option>
                            <option v-for="(item,o) in area_units" :key="o" :value="item.id">{{ item.title }}</option>
                        </select>
                        <span class="error-field" v-if="formRequestError.area_type && formRequest.area_type ==''"><span class="icon-warning"></span> {{ formRequestError.area_type }}</span>
                    </div> 
                </div>
            </template>

            <template v-if="step === 3 && category_features.length > 0">
                <h3>Features</h3>
                <div class="form-group featuredbox">
                    <ul>
                        <li v-for="(item,m) in category_features" :key="m" >
                            <input class="styled-checkbox" :id="'feature'+m" type="checkbox" v-model="formRequest.selected_features" :value="item.id">
                            <label :for="'feature'+m">{{ item.title }}</label>
                        </li>
                    </ul>
                </div>
            </template>

            <template v-if="step === 4">
                <div class="form-group row">
                    <div class="col-xl-6 mb-3">                                
                        <input v-model="formRequest.price" min="1" type="number" class="univ-input" placeholder="Price" >
                        <span class="error-field" v-if="formRequestError.price"><span class="icon-warning"></span> {{ formRequestError.price }}</span>
                    </div>
                    <div class="col-xl-6 mb-3">                                
                        <select class="univ-input" v-model="formRequest.price_condition">
                            <option value="Negotiable">Negotiable</option>
                            <option value="Fixed">Fixed</option>
                        </select>
                    </div>
                    <div class="col-xl-6 mb-3" v-if="formRequest.purpose =='rent'">                                
                        <select class="univ-input" v-model="formRequest.price_for">
                            <option value="Month">Per Month</option>
                            <option value="Year">Per Year</option>
                        </select>
                    </div>
                    
                    <div class="col-xl-12">
                        <AddPropertyImage :selecteImages="selectedImages" @selected-images="handleSelectedImages($event)"/>
                        <span class="error-field" v-if="formRequestError.property_images && formRequest.selected_images.length < 1"><span class="icon-warning"></span> {{ formRequestError.property_images }}</span>
                    </div>
                </div>
            </template>

            <template>
                <div class="form-group row">
                    <div class="col-xl-6 col-6 mb-3">
                        <div v-if="step === 2 || step === 3 || step === 4"  @click.prevent="prev()">
                            <button class="back-btn w-100">Back</button>
                        </div>
                    </div>
                    <div class="col-xl-6 col-6 mb-3">
                        <button v-if="step === 4" @click.prevent="formRequestSubmit" class="univ-btn w-100"><b-spinner v-if="actionLoader" small></b-spinner> {{ formAction }} Property </button>
                        <button v-else @click.prevent="next()" class="univ-btn w-100"><b-spinner v-if="actionLoader" small></b-spinner> Next </button>
                    </div>
                </div>
            </template>
            
        </div>                    
    </div>
</b-modal>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import store from "@/store";
import Helper from '../services/helper';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import AddPropertyImage from '@/views/Users/AddProperty/partials/AddPropertyImage';
import Multiselect from 'vue-multiselect'
import LocationAutocomplete from './LocationAutocomplete';

export default {
    name: 'AddPropertyModal',
    components: {AddPropertyImage,Multiselect,LocationAutocomplete,DatePicker},
    props: {
        propertySlug:{
            required:false,
        }
    },
    data() {
        return {
            formAction:"Add",
            step: 1,
            steps:{
                step_1_valid:false,
                step_2_valid:false,
                step_3_valid:false,
            },
            titleCounter:0,
            textCounter:0,
            titleMaxLength: 50,
            textMaxLength:500,
            selecteAddress:"",
            selectedImages: [],


            actionLoader:false,
            land_size:"",
            purpose:"",
            category:"",
            types:"",
            property_purpose:[],
            property_category:[],
            property_categories_detail:[],
            selecteCategoryID:"",
            selectedCategoryName:"",
            selectedDistrictName:"",
            category_types:[],
            category_features:[],
            location:[],
            area_units:[],

            formRequest:{
                slug:"",
                client_id: store.getters.user.id,
                purpose:"",
                category_id:"",
                category_type_id:"",
               
                title:"",
                description:"",
                location_id:"",
                address:"",
                land_mark:"",
                lat:"",
                lon:"",
                price:"",
                price_condition:"Negotiable",
                price_for:"Month",
                currency:"NPR",
                expire_date:"",

                land_size_type:"",
                ropani:"",
                anna:"",
                paisa:"",
                dam  :"",
                bigha:"",
                katha:"",
                dhur :"",

                road_size:"",
                road_type:"",
                build_date:"",

                floor:"",
                bedroom:"",
                bathroom:"",
                livingroom:"",
                kitchen:"",
                furnishing:"",

                buildup:"",
                area_type:"",
                
                selected_features: [],
                selected_images: [],
            },
            formRequestError:{
                purpose:"",
                category_id:"",
                category_type_id:"",
                title:"",
                description:"",
                location_id:"",
                address:"",
                price:"",

                property_images:"",
            },
        };        
    },
    mounted() {
        let app = this;
        app.renderInfo();
        if(app.propertySlug !=""){
            app.formAction = "Update";
            app.renderPropertyDetail();
        }
    },
    methods: {
        async renderInfo(){
            let app = this;
            await axios.get(api.getUrl('/general-info')).then(function (response) {
                let responseData        = response.data.data;
                if(response.data.success){
                    let purposeRows = [];
                    responseData.property_purpose.forEach(function (item) {
                        purposeRows.push({title:item.title,slug:item.slug});
                    });
                    app.property_purpose = purposeRows;
                    let categoryRows = [];
                    responseData.property_category.forEach(function (item) {
                        categoryRows.push({title:item.title,id:item.id});
                    });
                    app.property_category = categoryRows;
                    app.property_categories_detail   = responseData.property_category;

                    app.property_type       = responseData.property_type;
                    app.location            = responseData.location;
                    app.area_units          = responseData.area_units;
                }
            }).finally(() => {
                
            });
        },
        updateTitleCount(){
            this.titleCounter = this.formRequest.title.length;
        },
        updateTextCount(){
            this.textCounter = this.formRequest.description.length;
        },
        formRequestSubmit(){
            let app = this;
            let url;
            app.actionLoader = true;
            Helper.showSpinner();
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            if(app.formRequest.slug ==""){
                url = api.getUrl('/user/property/store');
            }else{
                url = api.getUrl('/user/property/update');
            }
            axios.post(url, app.formRequest ,config).then(function (response) {
                if(response.data.success){
                    app.actionLoader = false;
                    app.formRequest.selected_images = [];
                    app.message = response.data.message;
                    Helper.hideSpinner();
                    app.$toast.success(response.data.message,{position: "top-center",timeout: 1500});
                    app.$root.$emit("property-updated", true);
                    app.closeModal();
                }else{
                    if(response.data.message){
                        app.actionLoader = false;
                        Helper.hideSpinner();
                        app.message = response.data.message;
                        app.$toast.error(response.data.message,{position: "top-center",timeout: 1500});
                    }else if(response.data.errors.length > 0){
                        app.actionLoader = false;
                        Helper.hideSpinner();
                        app.handleFormErrors(response.data.errors);
                    }
                }
            });
        },
        prev() {
            this.step--;
        },
        next() {
            this.actionStatus();
            this.clearReqErrors();
            if(this.step === 1 && this.steps.step_1_valid){
                this.step++;
                this.selecteAddress =  this.formRequest.address;
            }else if(this.step === 2 && this.steps.step_2_valid){
                this.step++;
                this.selectedImages = this.formRequest.selected_images;
            }else if(this.step === 3){
                this.step++;
            }else{
                this.formRequestSubmit();
            }
        },
        handleFormErrors($event){
            let app = this;
            $event.forEach(function(item) {
                if(item.key == "purpose"){
                    app.formRequestError.purpose = item.message;
                }else if(item.key == "category_id"){
                    app.formRequestError.category_id = item.message;
                }else if(item.key == "category_type_id"){
                    app.formRequestError.category_type_id = item.message;

                }else if(item.key == "title"){
                    app.formRequestError.title = item.message;
                }else if(item.key == "description"){
                    app.formRequestError.description = item.message;

                }else if(item.key == "location_id"){
                    app.formRequestError.location_id = item.message;
                }else if(item.key == "address"){
                    app.formRequestError.address = item.message;
                }else if(item.key == "price"){
                    app.formRequestError.price = item.message;
                }else if(item.key == "property_images"){
                    app.formRequestError.property_images = item.message;
                }
            }); 
        },
        handleSelectedImages($event){
            let app = this;
            app.formRequest.selected_images = $event;
        },
        purposeLabel(option) {
            return `${option.title}`;
        },
        getSelectedPurpose(option){
            let app = this;
            app.formRequest.purpose = option.slug;
        },
        removedSelectedPurpose(){
            let app = this;
            app.formRequest.purpose = "";
        },
        categoryLabel(option) {
            return `${option.title}`;
        },
        getSelectedCategory(option){
            let app = this;
            app.types = "";
            app.formRequest.category_id = option.id;
            // onchange event
            app.resetSpecification();
            app.selecteCategoryID = option.id;
            app.selectedCategoryName = option.title;
            if(app.selecteCategoryID == ''){
                app.resetTypes();
            }
            app.property_categories_detail.forEach(function (item) {
                if (item.id == app.selecteCategoryID) {
                    let typesRows = [];
                    item.category_types.forEach(function (item) {
                        typesRows.push({title:item.title,id:item.id});
                    });
                    app.category_types = typesRows;
                    app.category_features = item.category_features;
                }
            });
        },
        removedSelectedCategory(){
            let app = this;
            app.formRequest.category_id = "";
            app.resetTypes();
        },
        typeLabel(option) {
            return `${option.title}`;
        },
        getSelectedType(option){
            let app = this;
            app.formRequest.category_type_id = option.id;
        },
        removedSelectedType(){
            let app = this;
            app.formRequest.category_type_id = "";
        },
        resetTypes(){
            let app = this;
            app.selectedCategoryName = "";
            app.formRequest.category_type_id = "";
            app.category_types    = [];
            app.category_features = [];
        },
        selecteDistrict($event){
            this.selectedDistrictName = $event.target.options[event.target.options.selectedIndex].text;
        },
        selectedLocationHandle($event){
            let app = this;
            app.formRequest.address = $event.address;
            app.formRequest.lat = $event.lat;
            app.formRequest.lon = $event.lon;
        },
        onInputLandSize(event) {
            this.land_size = event.target.value.replace(/-/g, '').match(/(\d{1,10})/g)[0];
			// Format display value based on calculated currencyValue
            this.formRequest.land_size = this.land_size.replace(/(\d{1,2})(\d{1,2})(\d{1,2})(\d{1,2})/g, '$1-$2-$2-$2');
        },
        actionStatus(){
            let app = this;
            if(app.step === 1 && app.formRequest.title && app.formRequest.description && app.formRequest.location_id && app.formRequest.address){
                app.steps.step_1_valid = true;
            }else if(app.step === 2 && app.formRequest.category_id && app.formRequest.category_type_id && app.formRequest.purpose){
                app.steps.step_2_valid = true;
            }else if(app.step === 3){
                app.steps.step_3_valid = true;
            }
        },
        clearFormRequest(){
            let app = this;
            app.step = 1;
            app.steps.step_1_valid = false;
            app.steps.step_2_valid = false;
            app.steps.step_3_valid = false;

            app.formRequest.slug            = "";
            app.formRequest.purpose         = "";
            app.formRequest.category_id     = "";
            app.formRequest.category_type_id= "";

            app.formRequest.title           = "";
            app.formRequest.description     = "";

            app.formRequest.location_id = "";
            app.formRequest.address     = "";
            app.selecteAddress          = "";

            app.formRequest.land_mark   = "";
            app.formRequest.lat         = "";
            app.formRequest.lon         = "";
            app.formRequest.price       = "";
            app.formRequest.price_condition= "";
            app.formRequest.price_for   = "";
            app.formRequest.currency    = "";
            app.formRequest.expire_date = "";
        
            app.formRequest.land_size   = "";
            app.formRequest.ropani  ="";
            app.formRequest.anna    ="";
            app.formRequest.paisa   ="";
            app.formRequest.dam     ="";
            app.formRequest.bigha   ="";
            app.formRequest.katha   ="";
            app.formRequest.dhur    ="";

            app.formRequest.road_size   = "";
            app.formRequest.road_type   = "";
            app.formRequest.build_date  = "";

            app.formRequest.floor       = "";
            app.formRequest.bedroom     = "";
            app.formRequest.bathroom    = "";
            app.formRequest.livingroom  = "";
            app.formRequest.kitchen     = "";
            app.formRequest.furnishing  = "";

            app.formRequest.buildup     = "";
            app.formRequest.area_type   = "";
            
            app.formRequest.selected_features= [];
            app.formRequest.selected_images = [];
        },
        clearReqErrors(){
            let app = this;
            app.formRequestError.purpose = "",
            app.formRequestError.category_id = "";
            app.formRequestError.category_type_id= ""; 
            app.formRequestError.title = "";
            app.formRequestError.description = "";
            app.formRequestError.location_id = "";
            app.formRequestError.address = "";
            app.formRequestError.price = "";
            app.formRequestError.property_images = "";
        },
        closeModal(){
            let app = this;
            app.clearFormRequest();
            app.$bvModal.hide('addPropertyModal');
        },
        resetSpecification(){
            let app = this;
            app.formRequest.floor = "";
            app.formRequest.bedroom = "";
            app.formRequest.bathroom = "";
            app.formRequest.livingroom = "";
            app.formRequest.kitchen = "";
            app.formRequest.furnishing = "";
        },
        async renderPropertyDetail() {
            let app = this;
            Helper.showSpinner();
            await axios.get(api.getUrl("/properties/" + app.propertySlug)).then(function (response) {
                if (response.data.success) {
                    app.property = response.data.data;

                    app.purpose                     = app.property.propertyPurpose;
                    app.category                    = app.property.category;
                    app.types                       = app.property.type;

                    app.formRequest.slug            = app.propertySlug;
                    app.formRequest.purpose         = app.property.purpose;
                    app.formRequest.category_id     = app.property.category_id;
                    app.formRequest.category_type_id= app.property.category_type_id;
                    

                    app.formRequest.title           = app.property.title;
                    app.formRequest.description     = app.property.description;

                    app.formRequest.location_id = app.property.location_id;
                    app.formRequest.address     = app.property.address;
                    app.selecteAddress          = app.property.address;

                    app.formRequest.land_mark   = app.property.land_mark;
                    app.formRequest.lat         = app.property.lat;
                    app.formRequest.lon         = app.property.lon;
                    app.formRequest.price       = app.property.price;
                    app.formRequest.price_condition= app.property.price_condition;
                    app.formRequest.price_for   = app.property.price_for;
                    app.formRequest.currency    = "NPR";
                    app.formRequest.expire_date = app.property.expire_date;
             
                    app.formRequest.land_size   = app.property.land_size;
                    app.formRequest.land_size_type = app.property.land_size_type,
                    app.formRequest.ropani  = app.property.ropani,
                    app.formRequest.anna    = app.property.anna,
                    app.formRequest.paisa   = app.property.paisa,
                    app.formRequest.dam     = app.property.dam,
                    app.formRequest.bigha   = app.property.bigha,
                    app.formRequest.katha   = app.property.katha,
                    app.formRequest.dhur    = app.property.dhur,

                    app.formRequest.road_size   = app.property.road_size;
                    app.formRequest.road_type   = app.property.road_type;
                    app.formRequest.build_date  = app.property.build_date;

                    app.formRequest.floor       = app.property.floor;
                    app.formRequest.bedroom     = app.property.bedroom;
                    app.formRequest.bathroom    = app.property.bathroom;
                    app.formRequest.livingroom  = app.property.livingroom;
                    app.formRequest.kitchen     = app.property.kitchen;
                    app.formRequest.furnishing  = app.property.furnishing;

                    app.formRequest.buildup     = app.property.buildup;
                    app.formRequest.area_type   = app.property.area_type.id;
                    
                    app.category_features       = app.property.property_features;
                    app.property.property_features.forEach(function (item) {
                        app.formRequest.selected_features.push(item.id);
                    });
                    app.formRequest.selected_images = app.property.property_images;
                    app.$bvModal.show('addPropertyModal');
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
        landSizeChange(e){
            let app = this;
            if(e.target.value =='rapd'){
                app.formRequest.bigha   ="";
                app.formRequest.katha   ="";
                app.formRequest.dhur    ="";
            }else if(e.target.value =='bkd'){
                app.formRequest.ropani  ="";
                app.formRequest.anna    ="";
                app.formRequest.paisa   ="";
                app.formRequest.dam     ="";
            }else{
                app.formRequest.ropani  ="";
                app.formRequest.anna    ="";
                app.formRequest.paisa   ="";
                app.formRequest.dam     ="";
                app.formRequest.bigha   ="";
                app.formRequest.katha   ="";
                app.formRequest.dhur    ="";
            }
        }
    },
    watch:{
        propertySlug(value){
            let app = this;
            app.page_slug = value;
            app.clearFormRequest();
            app.clearReqErrors();
            if(app.propertySlug !=""){
                app.formAction = "Update";
                app.renderPropertyDetail();
            }
        },
        $route() {
            if (store.getters.authenticated) {
                this.formRequest.client_id = store.getters.user.id; 
            } 
        }
    }
}
</script>