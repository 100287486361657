import Vue from 'vue'

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(Toast);
// Bootstrap
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)


import App from './App.vue'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './assets/css/common.css'
import './assets/css/responsive.css'
import './assets/css/icons.css'
import store from './store'
import router from './router'
import vuescroll from 'vuescroll'

import VideoBackground from 'vue-responsive-video-background-player'

Vue.component('video-background', VideoBackground);

Vue.use(vuescroll);

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
