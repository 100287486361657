<template>
    <div v-if="allImages.length > 0" class="property-design">
        <CoolLightBox :items="ig" :index="galleryIndex" @close="galleryIndex = null"></CoolLightBox>
        <VueSlickCarousel :options="carouselOptions" @swiped="handleSwipe" @afterChange="handleAfterChange" ref="carousel" class="vue-carousel-img">
            <div v-for="(image, index) in allImages" :key="index">
                <img :src="image.image_path" class="vue-img-main" draggable="false" @click="openGallery(index)" />
            </div>
        </VueSlickCarousel>
        <VueSlickCarousel
        ref="c2"
        :asNavFor="$refs.carousel"
        :slidesToShow="4"
        :focusOnSelect="true">
        <div class="thumb-img" v-for="(image, i) in allImages" :key="i" @click="activateImage(i)">
            <img :src="image.image_path" :class="{ active: activeImage === i }" />
        </div>
        </VueSlickCarousel>
    </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
    name: "ImgSlider",
    components: { VueSlickCarousel, CoolLightBox },
    props:{
        images:{
            required: true,
        }
    },
    data() {
        return {
            allImages: [],
            carouselOptions: {
                slidesToShow: 1,
                dots: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 5000,
                draggable: true,
                swipeToSlide: true,
                touchThreshold: 10,
            },
            activeImage: 0,
            galleryIndex: null,
        };
    },
    computed: {
        currentImage() {
            return this.allImages[this.activeImage].image_path;
        },
        ig() {
            return this.allImages.map((file) => file.image_path);
        },
    },
    methods: {
        activateImage(index) {
            this.activeImage = index;
            this.$refs.carousel.goTo(index);
        },
        handleSwipe(event) {
            const direction = event.direction === "left" ? 1 : -1;
            this.activeImage += direction;
        },
        handleAfterChange(index) {
            this.activeImage = index;
        },
        openGallery(index) {
            this.galleryIndex = index;
        },
    },
    watch: {
        images(value) {
            let app = this;
            app.allImages = value;
        },
    },
};
</script>