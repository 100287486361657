<template>
    <div class="autocomplete">
        <input type="text" class="univ-input" placeholder="Enter property address" v-model="search" @input="onChange" />
        <ul v-if="isOpen" class="autocomplete-results">
            <li class="loading" v-if="isLoading" > Loading results... </li>
            <li v-else v-for="(result, i) in results" :key="i" @click="setResult(result)" >
                {{ result.display_name }}
            </li>
        </ul>
    </div>
</template>
<style>
  .autocomplete {
    position: relative;
  }

  .autocomplete-results {
    position: absolute;
    font-size: 0.8125rem;
    background-color: #fff;
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    min-height: 1em;
    max-height: 15em;    
    overflow: auto;
    border-radius: 0.25rem;
    width: 100%;
    z-index: 99;
  }

  .autocomplete-results li {
    list-style: none;
    text-align: left;
    padding: 5px 10px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .autocomplete-results li:hover {
    background-color: #4AAE9B;
    color: white;
  }
</style>
<script>
import axios from 'axios'
import api from '@/services/api'

export default {
    name: 'LocationAutocomplete',
    props:{
        address:{
            required:true,
        }
    },
    data() {
        return {
            search:"",
            selectedLocation:{
                id:"",
                address: "",
                lat:"",
                lon:"",
            },
            results: [],
            isOpen: false,
            isLoading:false,
        };
    },
    mounted() {
        let app = this;
        app.search = app.address;
        document.addEventListener('click', app.handleClickOutside);
    },
    destroyed() {
        let app = this;
        document.removeEventListener('click', app.handleClickOutside);
    },
    methods: {
        onChange() {
            let app = this;
            if(app.search.length > 4){
                app.isOpen = true;
                app.isLoading = true;
                axios.get(api.getUrl(`/location?placesKey=${app.search}`)).then(function (response) {
                    if(response.data.success){
                        app.results = response.data.data;
                    }
                }).finally(() => {
                    app.isLoading = false;
                });
            }else{
                app.isOpen = false;
            }
        },
        setResult(result) {
            let app = this;
            app.search                  = result.display_name;
            app.selectedLocation.id       = result.id;
            app.selectedLocation.address  = result.display_name;
            app.selectedLocation.lat      = result.lat;
            app.selectedLocation.lon      = result.lon;
            app.isOpen = false;
            app.$emit('selected-location',app.selectedLocation);
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        }
    },
    watch:{
        address(value){
            this.search = value;
        }
    }
};
</script>