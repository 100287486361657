const state = {
    authenticated: false,
    accessToken: "",
    user:"",
    loginSource:"login-btn",
    userWishlist:"",
}
const getters = {
    authenticated: state => state.authenticated,
    accessToken: state => state.accessToken,
    user: state => state.user,
    loginSource: state => state.loginSource,
    userWishlist:state => state.userWishlist,
}
const mutations = {
    setAuthentication(state, status) {
        state.authenticated = status;
    },
    setAccessToken(state, token) {
        state.accessToken = token;
    },
    setUserData(state, data) {
        state.user = data;
    },
    setLoginSource(state, source) {
        state.loginSource = source;
    },
    setUserWishlist(state,data){
        state.userWishlist = data;
    },
}


export default {
    state,
    getters,
    mutations,
}